import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  /* canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.roles;
      //const userRole = localStorage.getItem("USERTYPE");
      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        this.router.navigate(["/authentication/signin"]);
        return false;
      }
      return true;
    }

    this.router.navigate(["/authentication/signin"]);
    return false;
  } */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const userRole = this.authService.currentUserValue.roles;
      const userRole = localStorage.getItem("USERTYPE");
      /* if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        this.router.navigate(["/authentication/signin"]);
        return false;
      } */
      return true;
  }
}
